// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("trix")
require('../js/bootstrap-datepicker.min')
//require('../js/tempusdominus-bootstrap-4.min')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'bootstrap'
import 'selectize/dist/js/selectize.min.js'
import '../stylesheets/application'
import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
import '@fortawesome/fontawesome-free/scss/regular.scss';
import '@fortawesome/fontawesome-free/scss/solid.scss';
import Inputmask from "inputmask";
import SignaturePad from "signature_pad";


SignaturePad.prototype.fromDataURL = function (dataUrl, options, callback) {
  var _this = this;
  if (options === void 0) { options = {}; }
  var image = new Image();
  // var ratio = options.ratio || window.devicePixelRatio || 1;
  // var width = options.width || this.canvas.width / ratio;
  // var height = options.height || this.canvas.height / ratio;
  this._reset();
  image.onload = function () {
      _this._ctx.drawImage(image, 0, 0);
      if (callback) {
          callback();
      }
  };
  image.onerror = function (error) {
      if (callback) {
          callback(error);
      }
  };
  image.src = dataUrl;
  this._isEmpty = false;
};

(function($) {

  var cocoon_element_counter = 0;

  var create_new_id = function() {
    return (new Date().getTime() + cocoon_element_counter++);
  }

  var newcontent_braced = function(id) {
    return '[' + id + ']$1';
  }

  var newcontent_underscord = function(id) {
    return '_' + id + '_$1';
  }

  var getInsertionNodeElem = function(insertionNode, insertionTraversal, $this){

    if (!insertionNode){
      return $this.parent();
    }

    if (typeof insertionNode == 'function'){
      if(insertionTraversal){
        console.warn('association-insertion-traversal is ignored, because association-insertion-node is given as a function.')
      }
      return insertionNode($this);
    }

    if(typeof insertionNode == 'string'){
      if (insertionTraversal){
        return $this[insertionTraversal](insertionNode);
      }else{
        return insertionNode == "this" ? $this : $(insertionNode);
      }
    }

  }

  $(document).on('click', '.add_fields', function(e) {
    e.preventDefault();
    e.stopPropagation();
    
    var $this                 = $(this),
        assoc                 = $this.data('association'),
        assocs                = $this.data('associations'),
        content               = $this.data('association-insertion-template'),
        insertionMethod       = $this.data('association-insertion-method') || $this.data('association-insertion-position') || 'before',
        insertionNode         = $this.data('association-insertion-node'),
        insertionTraversal    = $this.data('association-insertion-traversal'),
        count                 = parseInt($this.data('count'), 10),
        regexp_braced         = new RegExp('\\[new_' + assoc + '\\](.*?\\s)', 'g'),
        regexp_underscord     = new RegExp('_new_' + assoc + '_(\\w*)', 'g'),
        new_id                = create_new_id(),
        new_content           = content.replace(regexp_braced, newcontent_braced(new_id)),
        new_contents          = [],
        originalEvent         = e;


    if (new_content == content) {
      regexp_braced     = new RegExp('\\[new_' + assocs + '\\](.*?\\s)', 'g');
      regexp_underscord = new RegExp('_new_' + assocs + '_(\\w*)', 'g');
      new_content       = content.replace(regexp_braced, newcontent_braced(new_id));
    }

    new_content = new_content.replace(regexp_underscord, newcontent_underscord(new_id));
    new_contents = [new_content];

    count = (isNaN(count) ? 1 : Math.max(count, 1));
    count -= 1;

    while (count) {
      new_id      = create_new_id();
      new_content = content.replace(regexp_braced, newcontent_braced(new_id));
      new_content = new_content.replace(regexp_underscord, newcontent_underscord(new_id));
      new_contents.push(new_content);

      count -= 1;
    }

    var insertionNodeElem = getInsertionNodeElem(insertionNode, insertionTraversal, $this)

    if( !insertionNodeElem || (insertionNodeElem.length == 0) ){
      console.warn("Couldn't find the element to insert the template. Make sure your `data-association-insertion-*` on `link_to_add_association` is correct.")
    }

    $.each(new_contents, function(i, node) {
      var contentNode = $(node);

      var before_insert = jQuery.Event('cocoon:before-insert');
      insertionNodeElem.trigger(before_insert, [contentNode, originalEvent]);

      if (!before_insert.isDefaultPrevented()) {
        // allow any of the jquery dom manipulation methods (after, before, append, prepend, etc)
        // to be called on the node.  allows the insertion node to be the parent of the inserted
        // code and doesn't force it to be a sibling like after/before does. default: 'before'
        var addedContent = insertionNodeElem[insertionMethod](contentNode);

        insertionNodeElem.trigger('cocoon:after-insert', [contentNode,
          originalEvent]);
      }
    });
  });

  $(document).on('click', '.remove_fields.dynamic, .remove_fields.existing', function(e) {
    console.log('test');
    var $this = $(this),
        wrapper_class = $this.data('wrapper-class') || 'nested-fields',
        node_to_delete = $this.closest('.' + wrapper_class),
        trigger_node = node_to_delete.parent(),
        originalEvent = e;

    e.preventDefault();
    e.stopPropagation();

    var before_remove = jQuery.Event('cocoon:before-remove');
    trigger_node.trigger(before_remove, [node_to_delete, originalEvent]);

    if (!before_remove.isDefaultPrevented()) {
      var timeout = trigger_node.data('remove-timeout') || 0;

      setTimeout(function() {
        if ($this.hasClass('dynamic')) {
            node_to_delete.detach();
        } else {
            $this.prev("input[type=hidden]").val("1");
            node_to_delete.hide();
        }
        trigger_node.trigger('cocoon:after-remove', [node_to_delete,
          originalEvent]);
      }, timeout);
    }
  });

  $(document).on("ready page:load turbolinks:load", function() {
    $('.remove_fields.existing.destroyed').each(function(i, obj) {
      var $this = $(this),
          wrapper_class = $this.data('wrapper-class') || 'nested-fields';

      $this.closest('.' + wrapper_class).hide();
    });

    $('#payments').on('cocoon:after-insert', function(e, insertedItem, originalEvent) {
    
      $('.datepicker').datepicker({
        format: "dd-mm-yyyy",
        weekStart: 1,
        language: "pl",
        autoclose: true
      });
  
      insertedItem.find('.apartament_payments_title input').val('Etap '+ $('#payments .nested-fields').length);
    });

    $('.datepicker').datepicker({
      format: "dd-mm-yyyy",
      weekStart: 1,
      language: "pl",
      autoclose: true
    });
  });

})(jQuery);

jQuery(function ($) {

  $('#payments').on('cocoon:after-insert', function(e, insertedItem, originalEvent) {
    
    $('.datepicker').datepicker({
      format: "dd-mm-yyyy",
      weekStart: 1,
      language: "pl",
      autoclose: true
    });

    insertedItem.find('.apartament_payments_title input').val('Etap '+ $('#payments .nested-fields').length);
  });

//     $(".sidebar-dropdown > a").click(function() {
//   $(".sidebar-submenu").slideUp(200);
//   if (
//     $(this)
//       .parent()
//       .hasClass("active")
//   ) {
//     $(".sidebar-dropdown").removeClass("active");
//     $(this)
//       .parent()
//       .removeClass("active");
//   } else {
//     $(".sidebar-dropdown").removeClass("active");
//     $(this)
//       .next(".sidebar-submenu")
//       .slideDown(200);
//     $(this)
//       .parent()
//       .addClass("active");
//   }
// });
// $('.datetimepicker').datetimepicker();
   
});

document.addEventListener("turbolinks:load", () => {
  $(document).on('click', '#close-sidebar', function () {
    $(".page-wrapper").removeClass("toggled");
  });
  $(document).on('click', '#show-sidebar', function () {
      $(".page-wrapper").addClass("toggled");
  });

  $(".sidebar-dropdown > a").click(function() {
    $(".sidebar-submenu").slideUp(200);
    if (
      $(this)
        .parent()
        .hasClass("active")
    ) {
      $(".sidebar-dropdown").removeClass("active");
      $(this)
        .parent()
        .removeClass("active");
    } else {
      $(".sidebar-dropdown").removeClass("active");
      $(this)
        .next(".sidebar-submenu")
        .slideDown(200);
      $(this)
        .parent()
        .addClass("active");
    }
  });

  $(document).on('change', '.custom-file-input', function (event) {
    var files = [];
    for (var i = 0; i < $(this)[0].files.length; i++) {
        files.push($(this)[0].files[i].name);
    }
    $(this).next('.custom-file-label').html(files.length > 0 ? files.join(', ') : 'Wybierz');
  });

  autoToggleMenu();

  $(window).on('resize', function () {
    autoToggleMenu();
  });
  
  function autoToggleMenu() {
    if ($(window).width() > 1024) {
      $(".page-wrapper").addClass("toggled");
    } else {
      $(".page-wrapper").removeClass("toggled");
    }
  }

  $(document).on('click', '.mailto', function (e) {
    e.preventDefault();
    e.stopPropagation();
    window.location.href = 'mailto:' + $(this).text();
  });

  $('#sms_message_client_ids').selectize({
    delimiter: ',',
    persist: false,
    plugins: ['remove_button']
  });

  $(document).on('change', '#radio-buttons-with-comments input[type="radio"]', function () {
    var id = $(this).closest('tr').data('id');
    if($(this).val() == '1') {
      $('#radio-buttons-with-comments [data-id="' + id + '"] input[type="text"],#radio-buttons-with-comments [data-id="' + id + '"] textarea').removeAttr('disabled');
    } else {
      $('#radio-buttons-with-comments [data-id="' + id + '"] input[type="text"],#radio-buttons-with-comments [data-id="' + id + '"] textarea').attr('disabled','disabled').val('');
    }
  });

  $(document).on('change', '#additional-equipments-options input[type="radio"]', function () {
    var id = $(this).closest('tr').data('id');
    var name = $(this).attr('name').replace('additional_equipment_id','_destroy');
    if($(this).val() == "0") {
      $(this).parent().append('<input type="hidden" value="1" name="' + name + '" />');
      $('#additional-equipments-options [data-id="' + id + '"] input[type="text"]').each(function(i, obj) {
        $(this).attr('disabled','disabled').val('');
        var sub_name = $(this).attr('name').replace('content','_destroy');
        $(this).parent().append('<input type="hidden" value="1" name="' + sub_name + '" />');
      });
    } else {
      $('[name="' + name + '"]').remove();
      $('#additional-equipments-options [data-id="' + id + '"] input[type="text"]').each(function(i, obj) {
        $(this).attr('disabled','disabled').removeAttr('disabled');
        var sub_name = $(this).attr('name').replace('content','_destroy');
        $('[name="' + sub_name + '"]').remove();
      });
    }
  });

  var developerWrapper = document.getElementById("developer-signature-pad");
  var clientWrapper = document.getElementById("client-signature-pad");
  if (developerWrapper) {
    var developerClearButton = developerWrapper.querySelector("[data-action=clear]");
    var developerCanvas = developerWrapper.querySelector('canvas');
    var developerSignaturePad = new SignaturePad(developerCanvas, {
    // It's Necessary to use an opaque color when saving image as JPEG;
    // this option can be omitted if only saving as PNG or SVG
      backgroundColor: 'rgb(255, 255, 255)'
    });
    developerClearButton.addEventListener("click", function (event) {
      developerSignaturePad.clear();
      document.getElementById('protocol_developer_signature_data').value = '';
    });

    if (developerWrapper.dataset.signature) {
      // var developerImg = new Image();
      // developerImg.addEventListener("load", function(){
      //   developerCanvas.getContext('2d').drawImage(developerImg,0,0);
      // });
      // developerImg.src = developerWrapper.dataset.signature;
      developerSignaturePad.fromDataURL(developerWrapper.dataset.signature, { ratio: 72, width: developerCanvas.width, height: developerCanvas.height });
    }

    window.onresize = resizeCanvas(developerCanvas, developerSignaturePad);
    resizeCanvas(developerCanvas, developerSignaturePad);
  }

  if (clientWrapper) {
    var clientClearButton = clientWrapper.querySelector("[data-action=clear]");
    var clientCanvas = clientWrapper.querySelector('canvas');
    var clientSignaturePad = new SignaturePad(clientCanvas, {
    // It's Necessary to use an opaque color when saving image as JPEG;
    // this option can be omitted if only saving as PNG or SVG
      backgroundColor: 'rgb(255, 255, 255)'
    });
    clientClearButton.addEventListener("click", function (event) {
      clientSignaturePad.clear();
      document.getElementById('protocol_client_signature_data').value = '';
    });
    
    if (clientWrapper.dataset.signature) {
      // var clientImg = new Image();
      // clientImg.addEventListener("load", function(){
      //   clientCanvas.getContext('2d').drawImage(clientImg,0,0);
      // });
      // clientImg.src = clientWrapper.dataset.signature;
      // clientSignaturePad._isEmpty = false;
      clientSignaturePad.fromDataURL(clientWrapper.dataset.signature);
    }

    window.onresize = resizeCanvas(clientCanvas, clientSignaturePad);
    resizeCanvas(clientCanvas, clientSignaturePad);
  }

  function resizeCanvas(canvas, signaturePad) {
    var ratio =  Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext("2d").scale(ratio, ratio);
    signaturePad.clear();
  }

  $(document).on('click', '#save-protocol-button', function () {
    // (clientSignaturePad.isEmpty() || developerWrapper.dataset.signature.) ? (clientWrapper.querySelector('.signature-pad--body').style.borderColor = '#f00') : (clientWrapper.querySelector('.signature-pad--body').style.borderColor = '#304697');
    // developerSignaturePad.isEmpty() ? (developerWrapper.querySelector('.signature-pad--body').style.borderColor = '#f00') : (developerWrapper.querySelector('.signature-pad--body').style.borderColor = '#304697');

    // if (clientSignaturePad.isEmpty() || developerSignaturePad.isEmpty() ) {
    //   return false;
    // } else {      
    if (!clientSignaturePad.isEmpty()) {
      document.getElementById('protocol_client_signature_data').value = clientSignaturePad.toDataURL('image/svg+xml');//.replace('base64,','base64,/9j/');
    }

    if (!developerSignaturePad.isEmpty()) {
      document.getElementById('protocol_developer_signature_data').value = developerSignaturePad.toDataURL('image/svg+xml');//.replace('base64,','base64,/9j/');
    }
    // }
  });

  $(document).on('click', '#draft-protocol-button', function () {
    if (!clientSignaturePad.isEmpty()) {
      document.getElementById('protocol_client_signature_data').value = clientSignaturePad.toDataURL('image/svg+xml');//.replace('base64,','base64,/9j/');
    }

    if (!developerSignaturePad.isEmpty()) {
      document.getElementById('protocol_developer_signature_data').value = developerSignaturePad.toDataURL('image/svg+xml');//.replace('base64,','base64,/9j/');
    }
  });
});